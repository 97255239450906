import { PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export class KendoGridFilterColumnNameColumnValue {
    public columnName?: string | null;
    public columnValue?: string | null;
}

export class KendoMasterGridProperties {
    public static readonly pageNumber = 1;
    public static readonly pageSize = 10;
    public static readonly pageSizeLarge = 20;
    public static readonly pageSizeXLarge = 50;
    public static readonly pageSizeXXLarge = 100;
    public static readonly skip = 0;
    public static readonly take = 10;
    public static readonly loading = true;
    public static readonly pageSizes = [5, 10, 20, 50, 100];
    public static readonly type: PagerType = 'numeric';
    public static readonly buttonCount = 10;
    public static readonly info = true;
    public static readonly previousNext = true;
    public static readonly maxSearchRecords = 500;

    public static getPageNumber(_skip: number, _take: number): number {
        return (_skip / _take) + 1;
    }

    public static getFilterColumnNameColumnValue(filter: CompositeFilterDescriptor): KendoGridFilterColumnNameColumnValue {
        const kendoGridFilterColumnNameColumnValue = new KendoGridFilterColumnNameColumnValue();
        filter.filters.forEach((filterItem) => {
            const typeOfCompositeFilterDescriptor = <CompositeFilterDescriptor>filterItem;
            if (typeOfCompositeFilterDescriptor.filters !== undefined) {
                typeOfCompositeFilterDescriptor.filters.forEach((compositeFilterDescriptor) => {
                    kendoGridFilterColumnNameColumnValue.columnName = compositeFilterDescriptor['field'];
                    kendoGridFilterColumnNameColumnValue.columnValue = compositeFilterDescriptor['value'];
                });
            } else {
                kendoGridFilterColumnNameColumnValue.columnName = typeOfCompositeFilterDescriptor['field'];
                kendoGridFilterColumnNameColumnValue.columnValue = typeOfCompositeFilterDescriptor['value'];
            }
        });
        return kendoGridFilterColumnNameColumnValue;
    }
}

export class KendoModalGridProperties {
    public static readonly pageNumber = 1;
    public static readonly pageSize = 5;
    public static readonly skip = 0;
    public static readonly take = 5;
    public static readonly loading = true;
    public static readonly toSelectcheckboxOnly = false;
    public static readonly selectionMode = 'single';
    public static readonly selectionModeMulti = 'multiple';
    public static getPageNumber(_skip: number, _take: number): number {
        return (_skip / _take) + 1;
    }
}

export class KendoAutoCompleteFields {
    public static readonly custIdField = 'CustId';
    public static readonly progNbrField = 'ProgNbr';
    public static readonly diagCodeField = 'DiagCode';
    public static readonly ecmexIdField = 'EcmexId';
    public static readonly servCode1Field = 'ServCode1';
    public static readonly employeeField = 'Code';
    public static readonly hcpcsField = 'hCPCS';
    public static readonly account = 'Account';
    public static readonly BillCodeField = 'BillCodeId';
    public static readonly custLastNameField = 'LastName';
    public static readonly clientNameField = 'clientName';
    public static readonly custInsuredIdField = 'InsuredId';
    public static readonly batchIdField = 'BatchId';
    public static readonly serviceTypeCode = 'ServiceTypeCode';
    public static readonly billingDashboardTemplateField = 'Name';
    public static readonly mcnCode = 'mcnCode';
    public static readonly repIdField = 'personId';
    public static readonly ownerNameField = 'Name';
    public static readonly caseManagerId = 'personId';
}

export class KendoAutoCompleteProperties {
    public static readonly fieldOrder = 'asc';
    public static readonly popupSize = 10;
    public static readonly popupNumber = 1;
    public static readonly maxPageSize = 500;
}

export class KendoGridFilterColumn {
    public static readonly StatusColumn = 'Status';
}
