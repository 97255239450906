import {
  Component, EventEmitter, Input, OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of, Subject, take, takeUntil, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigurationFunctionService } from '../core/services/configurationapi/configurationfunctionapi.services';
import { NotificationFunctionService } from '../core/services/notificationsapi/notificationfunctionapi.services';
import { PersonIdResponse } from '../core/services/peopleapi/peoplefunctionapi.services';
import { UserService } from '../core/services/u2api.services';
import { CommonConstants } from '../shared/constants/common-constants';
import { FeatureStateService } from '../shared/services/featureStateService';
import { PersonCommonService } from '../shared/services/person-common.service';
import { CommonFunctions } from '../shared/utilities/common-functions';
import { ICompany } from './../core/models/company';
import { CDCNCompanyService } from './cdcn-company-service/cdcn-company.service';
import { IamWrapperService } from '../core/services/iam-wrapper.service';
import { ValidationErrorExpressionConstants } from '../shared/constants/error-expression-constants';
import { UnauthorizeService } from '../core/services/unauthorize.service';

@Component({
  selector: 'CDMS-Portal-U2-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() UserName: string;
  @Input() companyDatafromSideNav: any;
  @Output() sideNavClickChange = new EventEmitter();
  @Output() logOutClickEmitter = new EventEmitter();
  @Output() companyCodeEmitter = new EventEmitter<object>();
  @Output() userHasNoAccessEmitter = new EventEmitter();
  public companyList: Array<ICompany> = [];
  public companyCode: string;
  public selectedCompany: number;
  public notReadNotifications = 0;
  public showNotifications = false;
  public sidebarCollapseClick = false;
  public busyLoader = false;
  public moreCompaniesModelOpen = false;
  public existingCompanies = '';
  public personListFromObjectId: PersonIdResponse[];
  public personFromObjectId: any;
  public environment = '';
  public shift = false;
  public arrowLeft = false;
  public arrowRight = false;
  public ctrl = false;
  public loading = {
    getUserAccessedCompanies: false,
    getCompanyInfo: false,
    userLogout: false
  };
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private iamWrapperService: IamWrapperService,
    public toastr: ToastrService,
    private NotificationService: NotificationFunctionService,
    public configurationFunctionService: ConfigurationFunctionService,
    private router: Router,
    private featureState: FeatureStateService,
    public personCommonService: PersonCommonService,
    private cdcnCompanyService: CDCNCompanyService,
    public commonFunctions: CommonFunctions,
    public unauthorizeService: UnauthorizeService,
  ) {
    this.environment = environment.envName;
  }

  ngOnInit() {
    this.personCommonService.currentCount.subscribe(res => this.getUnreadCount());
    this.UserName = localStorage.getItem('UserName');
    this.loading.getUserAccessedCompanies = true;

    this.getUserCompanies()
      .pipe(
        take(1),
        takeUntil(this._destroying$)
      ).subscribe({
        next: (dt) => {
          this.loading.getUserAccessedCompanies = false;
          this.updateCompaniesList(dt.map(i => ({ CompanyId : i.companyId, CompanyName: i.companyName })));
          if (this.companyList.length <= 0) {
            this.userHasNoAccessEmitter.emit(true);
            return;
          }
          this.updateExistingCompanies();
          let company: ICompany;
          this.companyCode = sessionStorage.getItem('CompanyCode');
          if (this.companyCode) {
            company = this.companyList.find(x => x.CompanyName === this.companyCode);
            if (company) {
              this.companyCode = company.CompanyName;
              this.selectedCompany = company.CompanyId;
            } else {
              company = this.companyList[0];
              this.companyCode = company.CompanyName;
              this.selectedCompany = company.CompanyId;
            }
          } else {
            company = this.companyList[0];
            this.companyCode = company.CompanyName;
            this.selectedCompany = company.CompanyId;
          }
          sessionStorage.setItem('CompanyCode', this.companyCode);
          const companyData = {
            fromCompanyChange: false,
            companyCode: company
          };
          this.companyCodeEmitter.emit({ companyData, isCompanyChange: false });
          this.loading.getCompanyInfo = true;
          this.configurationFunctionService.getCompanyInfo()
            .pipe(
              take(1),
              takeUntil(this._destroying$)
            ).subscribe({
              next: (data) => {
                this.loading.getCompanyInfo = false;
                sessionStorage.setItem(CommonConstants.CompanyFeaturesStorageKey, btoa(JSON.stringify(data.features)));
                sessionStorage.setItem(CommonConstants.CompanyConfigStorageKey, btoa(JSON.stringify(data.configuration)));
                if (this.featureState.useIamApis()) {
                  this.iamWrapperService.handleDisplayBasedOnCompanies();
                }
              },
              error: error => {
                this.loading.getCompanyInfo = false;
                this.commonFunctions.handleErrorResponse(error, this.toastr, CommonConstants.UnableToLoadFeatures);
              }
            });
        },
        error: error => {
          this.loading.getUserAccessedCompanies = false;
          this.commonFunctions.handleErrorResponse(error, this.toastr, CommonConstants.UnableToLoadFeatures);
          this.userHasNoAccessEmitter.emit(true);
        }
      });
    this.getUnreadCount();
  }

  public getUserCompanies() {
    if (this.featureState.useIamApis()) {
      if (!this.unauthorizeService.isUnauthorized) {
        return this.getUserAccessedCompanies();
      } else {
        return throwError(() => new Error(ValidationErrorExpressionConstants.unAuthorize401ErrorMessage));
      }
    } else {
      return this.getUserAccessedCompanies();
    }
  }

  public getUserAccessedCompanies() {
    if (this.iamWrapperService.UserAccessedCompany) {
      return of(this.iamWrapperService.UserAccessedCompany);
    }
    else {
      return this.iamWrapperService.getUserAccessedCompanies();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyDatafromSideNav && changes.companyDatafromSideNav.currentValue) {
      this.updateCompaniesList(changes.companyDatafromSideNav.currentValue);
    }
    this.getUnreadCount();
  }

  Logout(): void {
    this.loading.userLogout = true;
    if (this.featureState.useIamApis()) {
      if (!this.unauthorizeService.isUnauthorized) {
        this.userLogout();
      } else {
        this.loading.userLogout = false;
        this.logOutClickEmitter.emit(true);
      }
    } else {
      this.userLogout();
    }
    this.busyLoader = true;
  }

  public userLogout() {
    this.iamWrapperService.clearUserCache()
      .pipe(
        take(1),
        takeUntil(this._destroying$)
      ).subscribe({
        next: (data: any) => {
          this.loading.userLogout = false;
          this.logOutClickEmitter.emit(true);
        },
        error: error => {
          this.loading.userLogout = false;
          this.commonFunctions.handleErrorResponse(error, this.toastr);
          this.logOutClickEmitter.emit(true);
        }
      });
  }

  sidebarClick(dir = '') {
    if(dir === 'left') {
      this.removeAllSubMenu();
      this.sidebarCollapseClick = true;
      this.sideNavClickChange.emit(true);
      return;
    } else if (dir === 'right') {
      this.sidebarCollapseClick = false;
      this.sideNavClickChange.emit(false);
      return;
    }
    this.removeAllSubMenu();
    this.sidebarCollapseClick = !this.sidebarCollapseClick;
    this.sideNavClickChange.emit(this.sidebarCollapseClick);
  }

  removeAllSubMenu() {
    const openSubmenu = Array.from(document.querySelectorAll('.show.sub-menu'));
    openSubmenu.forEach(element => {
      element.classList.remove('show');
    });
    this.openMenuArrow();
  }

  openMenuArrow() {
    const arrow = Array.from(document.querySelectorAll('b'));
    if (arrow) {
      arrow.forEach(element => {
        element.classList.remove('down');
        element.classList.add('right');
      });
    }
  }

  companyChange(eventData) {
    if (this.companyCode === eventData) {
      return;
    }
    const company = this.companyList.find(x => x.CompanyName === eventData);
    this.companyCode = company.CompanyName;
    this.selectedCompany = company.CompanyId;
    const companyData = {
      fromCompanyChange: true,
      companyCode: company
    };
    this.busyLoader = false;
    sessionStorage.setItem('CompanyCode', company.CompanyName);
    sessionStorage.setItem('CompanyId', company.CompanyId.toString());
    this.UserName =  localStorage.getItem('UserName');
    this.iamWrapperService.CompanyChangeInProgress = true;
    this.companyCodeEmitter.emit({ companyData, isCompanyChange: true });
    this.cdcnCompanyService.clearControllers();
    if (!this.featureState.useIamApis()) {
      this.loading.getCompanyInfo = true;
      this.configurationFunctionService.getCompanyInfo()
        .pipe(
          take(1),
          takeUntil(this._destroying$)
        ).subscribe({
          next: (data) => {
            this.loading.getCompanyInfo = false;
            sessionStorage.setItem(CommonConstants.CompanyFeaturesStorageKey, btoa(JSON.stringify(data.features)));
            sessionStorage.setItem(CommonConstants.CompanyConfigStorageKey, btoa(JSON.stringify(data.configuration)));
            if (this.featureState.useIamApis()) {
              this.iamWrapperService.handleDisplayBasedOnCompanies();
            }
          },
          error: error => {
            this.loading.getCompanyInfo = false;
            this.commonFunctions.handleErrorResponse(error, this.toastr, CommonConstants.UnableToLoadFeatures);
          }
        });
    }
  }

  getUnreadCount() {
    this.showNotifications = this.featureState.isNotificationEnabled();
    this.personListFromObjectId = JSON.parse(sessionStorage.getItem('PersonListFromObjectId'));
    if (this.personListFromObjectId && this.personListFromObjectId.length > 0) {
      this.personFromObjectId = this.personListFromObjectId[0].personId;
    }
    if (this.showNotifications && this.personFromObjectId) {
      this.NotificationService.getNotificationUnReadCnt(this.personFromObjectId).subscribe(result => {
        if (result) {
          this.notReadNotifications = result;
        } else {
          this.notReadNotifications = 0;
        }
      });
    }
  }
  moreCompaniesClick(eventData: string) {
    if (this.featureState.useIamApis()) {
      if (!this.unauthorizeService.isUnauthorized) {
        this.moreCompaniesModelClick(eventData);
      }
    } else {
      this.moreCompaniesModelClick(eventData);
    }
  }
  moreCompaniesModelClick(eventData: string) {
    if (eventData === 'open') {
      this.moreCompaniesModelOpen = true;
    } else {
      this.moreCompaniesModelOpen = false;
    }
  }
  moreCompaniesModelEvent(eventData: any) {
    this.moreCompaniesModelOpen = false;
    if (eventData.selected) {
      const company = <ICompany>eventData.item;
      this.companyCode = company.CompanyName;
      this.selectedCompany = company.CompanyId;
      const companyData = {
        fromCompanyChange: true,
        companyCode: company
      };
      this.busyLoader = false;
      sessionStorage.setItem('CompanyCode', company.CompanyName);
      this.companyCodeEmitter.emit({ companyData, isCompanyChange: false });
      this.loading.getCompanyInfo = true;
      this.configurationFunctionService.getCompanyInfo()
        .pipe(
          take(1),
          takeUntil(this._destroying$)
        ).subscribe({
          next: (data) => {
            this.loading.getCompanyInfo = false;
            sessionStorage.setItem(CommonConstants.CompanyFeaturesStorageKey, btoa(JSON.stringify(data.features)));
            sessionStorage.setItem(CommonConstants.CompanyConfigStorageKey, btoa(JSON.stringify(data.configuration)));
            if (this.featureState.useIamApis()) {
              this.iamWrapperService.handleDisplayBasedOnCompanies();
            }
          },
          error: error => {
            this.loading.getCompanyInfo = false;
            this.commonFunctions.handleErrorResponse(error, this.toastr, CommonConstants.UnableToLoadFeatures);
          }
        });
    }
  }

  updateCompaniesList(dt) {
    this.companyList = <Array<ICompany>>dt;
    this.companyList.sort((a, b) => {
      if (a.CompanyName > b.CompanyName) {
        return 1;
      } else if (b.CompanyName > a.CompanyName) {
        return -1;
      } else {
        return 0;
      }
    });
    this.updateExistingCompanies();
  }

  updateExistingCompanies() {
    this.existingCompanies = this.companyList.map(x => x.CompanyId).join(',');
  }

  goToNotificationsmessages() {
    this.router.navigateByUrl('Maintenance/Notifications/NotificationMessages/view');
  }


  debugChangeEnvironmentStyle(event) {
    if (!event.shiftKey || environment.envName === 'prod') { return; }
    const names = ['dev', 'qa', 'int', 'perf', 'alpha', 'migration', 'uat', 'preprod', 'prod'];
    let index = names.indexOf(this.environment) + 1;
    if (!names[index]) { index = 0; }
    this.environment = names[index];
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
