import { Injectable } from '@angular/core';
import { FeatureConstants } from '../../core/featureConstants';
import { CommonConstants } from '../constants/common-constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureStateService {

  isCCHEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CCH);
  }

  isQualificationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.Qualifications);
  }

  isNotificationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.Notifications);
  }

  isWWLEnabled(): boolean {
    return this.isEnabled(FeatureConstants.WWL);
  }

  isServiceCodeTasksEnabledAsync(): boolean {
    return this.isEnabled(FeatureConstants.ServiceCodeTasks);
  }

  isTasksEnabled(): boolean {
    return this.isEnabled(FeatureConstants.Tasks);
  }

  isAuthAllocationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.AuthAllocation);
  }

  isGHSEnabled(): boolean {
    return this.isEnabled(FeatureConstants.GHS);
  }

  isAdminTimeAdjustmentEnabled(): boolean {
    return this.isEnabled(FeatureConstants.AdminTimeAdjustment);
  }

  isSyncCareTeamToCellTrakEnabled(): boolean {
    return this.isEnabled(FeatureConstants.SyncCareTeamToCellTrak);
  }

  isPTOEnabled(): boolean {
    return this.isEnabled(FeatureConstants.PTO);
  }

  isPSSTEnabled(): boolean {
    return this.isEnabled(FeatureConstants.PSST);
  }

  isInternalAuthEnabled(): boolean {
    return this.isEnabled(FeatureConstants.InternalAuth);
  }

  isEmployerPayRatesEnabled(): boolean {
    return this.isEnabled(FeatureConstants.EmployerPayRates);
  }

  isEmployerDeductionDeleteEnabled(): boolean {
    return this.isEnabled(FeatureConstants.EmployerDeductionDelete);
  }

  isGeneratesAdminTimeEnabled(): boolean {
    return this.isEnabled(FeatureConstants.AdminTimeCreation);
  }

  isWaiverCodeSingleLineEdit(): boolean {
    return this.isEnabled(FeatureConstants.WaiverCodeSingleLineEdit);
  }

  isWaiverCodeMultiLineEdit(): boolean {
    return this.isEnabled(FeatureConstants.WaiverCodeMultiLineEdit);
  }

  isOkToWorkExemptEnabled(): boolean {
    return this.isEnabled(FeatureConstants.Qualifications);
  }

  coEmploymentAutoEmployerRelationship(): boolean {
    return this.isEnabled(FeatureConstants.CoEmploymentAutoEmployerRelationship);
  }

  coEmploymentEmployerOfRecordDisabled(): boolean {
    return this.isEnabled(FeatureConstants.CoEmploymentEmployerOfRecordDisabled);
  }

  isCaregiverDemographicsConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverDemographicsConfirmation);
  }

  isCaregiverParticipantRelationshipConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CGParticipantRelationshipConfirmation);
  }

  isCaregiverDifficultOfCareConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverDifficultOfCareConfirmation);
  }

  isCaregiverFICAConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverFICAConfirmation);
  }

  isCaregiverQualificationsConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverQualificationsConfirmation);
  }

  isCaregiverBackgroundCheckConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverBackgroundCheckConfirmation);
  }

  isCaregiverParticipantRequestRelationConfirmationEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CGPTReqRelConfirmation);
  }

  isPayrollBatchExportEnabled(): boolean {
    return this.isEnabled(FeatureConstants.PayrollBatchExport);
  }

  isPayrollReviewExportEnabled(): boolean {
    return this.isEnabled(FeatureConstants.PayrollReviewExport);
  }

  isCaregiverCoordinatorRelationshipEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverCoordinatorRelationship);
  }

  isParticipantCoordinatorRelationshipEnabled(): boolean {
    return this.isEnabled(FeatureConstants.ParticipantCoordinatorRelationship);
  }

    isLowerTemporaryWWLEnabled(): boolean {
        return this.isEnabled(FeatureConstants.LowerTemporaryWWL);
    }

    isStatusEnabled(): boolean {
        return this.isEnabled(FeatureConstants.Status);
    }

  isLowerTemporaryWwl(): boolean {
    return this.isEnabled(FeatureConstants.LowerTemporaryWwl);
  }

  isDisplayExternalKeysEnabled(): boolean {
    return this.isEnabled(FeatureConstants.DisplayExternalKeys);
  }

  isNetNegativeExclusionEnabled(): boolean {
    return this.isEnabled(FeatureConstants.NetNegativeExclusion);
  }

  isShowFamilial79fEnabled(): boolean{
    return this.isEnabled(FeatureConstants.ShowFamilial79f);
  }

  isShowCGPTCreateEnabled(): boolean{
    return this.isEnabled(FeatureConstants.ShowCGPTCreatButton);
  }

  isShowCaregiverParticipantAttestations(): boolean{
    return this.isEnabled(FeatureConstants.ShowCaregiverParticipantAttestations);
  }

  isUnitBasedEnabled(): boolean {
    return this.isEnabled(FeatureConstants.UnitBased);
  }

  isDollarBasedEnabled(): boolean {
    return this.isEnabled(FeatureConstants.DollarBased);
  }

  isHashEnabled(): boolean {
    return this.isEnabled(FeatureConstants.isHashingEnabled);
  }

  isCaregiverCoursesEnabled(): boolean {
    return this.isEnabled(FeatureConstants.CaregiverCourses);
  }

  isServiceCodeUsageEnabled(): boolean {
    return this.isEnabled(FeatureConstants.ServiceCodeUsageLimit);
  }

  isPayRatesInjectorEnabled(): boolean {
    return this.isEnabled(FeatureConstants.PayRatesInjector);
  }

  isFeaTransferEnabled(): boolean {
    return this.isEnabled(FeatureConstants.FEATransfer);
  }

  isSSNValidation(): boolean {
    return this.isEnabled(FeatureConstants.SSNValidation);
  }

  isSSNStatusShown(): boolean {
    return this.isEnabled(FeatureConstants.SSNStatus);
  }

  isSSNValidationOverrideEnabled(): boolean {
    return this.isEnabled(FeatureConstants.isSSNValidationOverrideEnabled);
  }

  isVASickTimeEnabled(): boolean {
    return this.isEnabled(FeatureConstants.VASickTime);
  }

  isUseNewIngestionFlowEnabled(): boolean {
    return this.isEnabled(FeatureConstants.UseNewIngestionFlow);
  }

  showCaregiverQualRolesOnCreate(): boolean {
    return this.isEnabled(FeatureConstants.ShowCaregiverQualRolesOnCreate);
  }

  u2LogErrorResponse(): boolean {
    return this.isEnabled(FeatureConstants.U2LogErrorResponse);
  }

  isEvvNewShiftServiceEnabled(): boolean {
    return this.isEnabled(FeatureConstants.EvvNewShiftService);
  }

  disassociateButtonEnabled(): boolean {
    return this.isEnabled(FeatureConstants.DisassociateButtonEnabled);
  }

  isVAOvertimeBillingEnabled(): boolean {
    return this.isEnabled(FeatureConstants.VAOvertimeBilling);
  }

  isU2NewShiftServiceEnabled(): boolean {
    return this.isEnabled(FeatureConstants.U2NewShiftService);
  }

  isOKToWorkDeterminedByCaregiverRoleTypeForParticipantEnabled(): boolean {
    return this.isEnabled(FeatureConstants.OKToWorkDeterminedByCaregiverRoleTypeForParticipant);
  }

  isTrainingServiceCodeExtensionUponQualificationExpiryEnabled(): boolean {
    return this.isEnabled(FeatureConstants.TrainingServiceCodeExtensionUponQualificationExpiry);
  }

  isUserCreationToolScreenEnabled(): boolean {
    return this.isEnabled(FeatureConstants.UserCreationToolScreen);
  }

  useIamApis(): boolean {
    return this.isEnabledWithDefaultValue(FeatureConstants.UseIamApi, true);
  }

  useDddApis(): boolean {
    return this.isEnabled(FeatureConstants.UseDddApi);
  }

  isDisableParticipantTasksEnabled(): boolean {
    return this.isEnabled(FeatureConstants.DisableParticipantTasks);
  }

  isShiftCommentsEnabled(): boolean {
    return this.isEnabled(FeatureConstants.ShiftComments);
  }

  isProspectiveRelationshipReviewAttestationsEnabled(): boolean {
    return this.isEnabled(FeatureConstants.ProspectiveRelationshipReviewAttestations);
  }

  isEnabled(featureName: string): boolean {
    // Gets company agnostic and company specific features from session data.
    const allFeaturesMap = this.GetAllFeaturesFromSession();

    if (allFeaturesMap && allFeaturesMap.has(featureName)) {
      return allFeaturesMap.get(featureName);
    }
    return false;
  }

  isEnabledWithDefaultValue(featureName: string, defaultValue: boolean = false): boolean {
    // Gets company agnostic and company specific features from session data.
    const allFeaturesMap = this.GetAllFeaturesFromSession();

    if (allFeaturesMap && allFeaturesMap.has(featureName)) {
      return allFeaturesMap.get(featureName);
    }
    return defaultValue;
  }

  private GetAllFeaturesFromSession() {
    const companyFeatures = sessionStorage.getItem(CommonConstants.CompanyFeaturesStorageKey);
    const companyAgnosticFeatures = sessionStorage.getItem(CommonConstants.CompanyAgnosticFeaturesStorageKey);
    const allFeatures = new Map<string, boolean>();

    if (companyAgnosticFeatures !== null && companyAgnosticFeatures !== '') {
      const companyAgnosticFeaturesJson = JSON.parse(atob(companyAgnosticFeatures));
      for (const [key, value] of Object.entries(companyAgnosticFeaturesJson)) {
        allFeatures.set(key, value as boolean);
      }
    }

    if (companyFeatures != null && companyFeatures !== '') {
      const companyFeaturesJson = JSON.parse(atob(companyFeatures));
      for (const [key, value] of Object.entries(companyFeaturesJson)) {
        allFeatures.set(key, value as boolean);
      }
    }
    return allFeatures;
  }
}
