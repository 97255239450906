import { map, Observable } from 'rxjs';
import { CompanyModel } from '../services/u2api.services';
import { UserResponseModel } from '../services/usersapi/usersapi.services';
import { TransformUtilities } from './utilities.transform';

export class GetUserAccessedCompaniesTransforms {
  static transformOutput(response$: Observable<UserResponseModel>): Observable<CompanyModel[]> {
    return response$.pipe(
      map((userData: UserResponseModel) =>
        TransformUtilities.ExtractUserAccessedCompanies(userData),
      ),
    );
  }
}
